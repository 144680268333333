<template>
	<div class="home">
		<div class="classify">
			<div class="classify-item" v-for="item in classifyList" :key="item.id">
				<img class="classify-img" :src="item.img" />
				<span>{{item.name}}</span>
			</div>
		</div>
		<el-carousel :interval="6000" class="home-carousel" height="5.12rem">
			<el-carousel-item v-for="item in carouselList" :key="item.id">
				<img class="banner-img" :src="item.img"/>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		name: "Home",
		components: {},
		data() {
			return {
				carouselList:[{
					img:require("../../assets/img/banner04.jpg")
				},{
					img:require("../../assets/img/banner05.jpg")
				}],
				classifyList: [{
					id: 1,
					name: "发动机系统",
					img: require("../../assets/img/classify-img01.png")
				}, {
					id: 2,
					name: "底盘",
					img: require("../../assets/img/classify-img02.png")
				}, {
					id: 3,
					name: "驾驶室",
					img: require("../../assets/img/classify-img03.png")
				}, {
					id: 4,
					name: "油品辅料",
					img: require("../../assets/img/classify-img04.png")
				}, {
					id: 5,
					name: "传动系统",
					img: require("../../assets/img/classify-img05.png")
				}, {
					id: 6,
					name: "行走系统",
					img: require("../../assets/img/classify-img06.png")
				}, {
					id: 7,
					name: "制动系统",
					img: require("../../assets/img/classify-img07.png")
				}, {
					id: 8,
					name: "转向系统",
					img: require("../../assets/img/classify-img08.png")
				}]
			};
		},
		created() {},
		methods: {

		},
	};
</script>
<style lang="scss" scoped>
	@import '~@/assets/css/home.scss';
</style>


