<template>
	<div class="container insure-details">
		<div class="insure-details-left">
			<QueryNavMenu :menuList="menuList" @categoryChange="handleCategoryChange"></QueryNavMenu>
		</div>
		<div class="insure-details-right">
			<insureDetailsCon :search=" { categoryId: categoryId, pinfoId: loss.pinfoId }"
                              :lossObj="loss"
                              @onAddChange="handleAddChange"
                              @onAddChangeList="handleAddChangeList"
                              @onAddLabor="handleAddLabor"
                              @onAddLaborList="handleAddLaborList"
                              @onRemove="handleRemove"
                              @onSubmit="handleSubmit"
                              @onUpdateChangeQty="handleUpdateChangeQty"
                              @onAddAccessory="handleAddAccessory"
                              @onUpdateAccessoryQty="handleUpdateAccessoryQty"
                              @onUpdateHourQty="handleUpdateHourQty"
                              @onAddCustome="handleAddCustome"
                              @selectionChange="spareSelectChange"></insureDetailsCon>
		</div>
	</div>
</template>

<script>
	import QueryNavMenu from "@/views/query/components/navMenu.vue"
	import insureDetailsCon from "./components/insureDetailsCon.vue"
    import { fetchCategoryTree } from "@/api/product";

    export default {
		components: {
			QueryNavMenu,
			insureDetailsCon
		},
		data() {
			return {
				menuList: [],
                loss: {
                    key: 0
                },
                categoryId: ''
			}
		},
		created() {
            //接收params参数
            this.loss = this.$route.params.loss;
            this.loss.key = 0
            this.getMenuList()
        },
		methods: {
            handleUpdateChangeQty(row, qty){
                let app = this
                let change = this.loss.changeList.find(item => item.productId === row.productId
                    && item.productName == row.productName)
                if(change.maxLoadQty < qty){
                    qty = change.maxLoadQty
                }
                change.qty = qty
                change.amount = this.common.accMul(qty, change.price)
                if(row.productId != 0) {
                    this.loss.laborList.filter(item => item.productId === row.productId
                        && item.productName == row.productName).forEach(e => {
                        e.qty = this.common.accMul(qty, e.sQty)
                        e.amount = app.common.accMul(e.qty, e.price)
                    })
                }
                this.calcAmount()
            },
            handleUpdateHourQty(row, qty){
                let app = this
                let hour = this.loss.laborList.find(item => item.productId === row.productId
                    && item.productName == row.productName && item.type == row.type)
                if(!hour){
                    return
                }
                hour.qty = qty
                hour.amount = app.common.accMul(qty, hour.price)
                this.calcAmount()
            },
            handleUpdateAccessoryQty(row, qty){
                let app = this
                let accessory = this.loss.accessoriesList.find(item => item.name === row.name
                    && item.spec == row.spec)
                console.log(accessory)
                accessory.qty = qty
                accessory.amount = app.common.accMul(qty, accessory.price)
                this.calcAmount()
            },
			spareSelectChange(){

			},
            handleCategoryChange(id) {
                this.categoryId = id
            },
            getMenuList() {
                fetchCategoryTree().then(res => {
                    this.menuList = res.data
                })
            },
            handleAddChange(row){
                this.addChangeItem(row)
                this.$message({
                    message: '添加换件成功!',
                    type: 'success'
                });
            },
            handleAddChangeList(list){
                list.forEach(item => {
                    this.addChangeItem(item)
                })
                this.$message({
                    message: '添加换件成功!',
                    type: 'success'
                });
            },
            handleAddLabor(row, val, qty, remark){
                this.addLaborItem(row, val, qty, remark)
                this.$message({
                    message: '添加工时成功!',
                    type: 'success'
                });
            },
            handleAddLaborList(list, val){
                list.forEach(item => {
                    if(val == '拆装') {
                        if (item.laborItem && item.laborItem.laborTime) {
                            this.addLaborItem(item, val, item.laborItem.laborTime, item.laborItem.laborTimeRemark)
                        }
                    }
                })
                this.$message({
                    message: '添加工时成功!',
                    type: 'success'
                });
            },
            addChangeItem(row){
                //查找是否已经存在该项目
                let index = this.loss.changeList.findIndex(item => item.productId === row.id
                    && item.productName == row.name)
                let qty = row.minQty ? row.minQty : 1
                let price = row.priceEndUser ? this.common.calcPriceService(row.priceEndUser, row.priceXichai) : 0
                if (index === -1) {
                    this.loss.changeList.push({
                        productId: row.id,
                        productCode: row.buyCode,
                        productName: row.name,
                        qty: qty,
                        price: price,
                        amount: this.common.accMul(qty, price),
                        maxLoadQty: row.laborItem && row.laborItem.maxLoadQty ? row.laborItem.maxLoadQty : 9999,
                    })
                    console.log(this.loss.changeList)
                } else {
                    if(row.laborItem && row.laborItem.maxLoadQty) {
                        this.loss.changeList[index].maxLoadQty = row.laborItem.maxLoadQty
                    }
                    this.handleUpdateChangeQty(this.loss.changeList[index],
                        this.common.accAdd(this.loss.changeList[index].qty, qty))
                    return
                }
                this.calcAmount()
            },
            addLaborItem(row, val, sQty, remark){
                //查找是否已经存在该项目
                let index = this.loss.laborList.findIndex(item => item.productId == row.id
                    && item.productName == row.name && item.type == val)
                if (index == -1) {
                    let qty = 1 * sQty
                    let change = this.loss.changeList.find(item => item.productId === row.id
                        && item.productName == row.name)
                    if(change){
                        qty = this.common.accMul(change.qty, sQty)
                    }

                    let price = 25
                    this.loss.laborList.push({
                        productId: row.id,
                        productCode: row.buyCode,
                        productName: row.name,
                        type: val,
                        sQty: sQty,
                        qty: qty,
                        price: price,
                        amount: this.common.accMul(qty, price),
                        remark: remark
                    })
                }
                this.calcAmount()
            },
            calcAmount(){
                this.common.calcAmount(this.loss)
            },
            handleRemove(changeList, laborList, accessoryList){
                for(let i = 0; i < changeList.length; i++){
                    let idx = this.loss.changeList.findIndex(item => item.productId == changeList[i].productId)
                    if(idx != -1){
                        this.loss.changeList.splice(idx, 1)
                    }
                }
                for(let i = 0; i < laborList.length; i++){
                    let idx = this.loss.laborList.findIndex(item => item.productId == laborList[i].productId
                        && item.type == laborList[i].type)
                    if(idx != -1){
                        this.loss.laborList.splice(idx, 1)
                    }
                }
                for(let i = 0; i < accessoryList.length; i++){
                    let idx = this.loss.accessoriesList.findIndex(item => item.name == accessoryList[i].name)
                    if(idx != -1){
                        this.loss.accessoriesList.splice(idx, 1)
                    }
                }
                this.$message({
                    message: '删除成功!',
                    type: 'success'
                });
            },
            handleSubmit(){
                this.$router.push({ name: 'insure', params: {loss: this.loss}})
            },
            handleAddAccessory(row){
                this.addAccessoryItem(row)
                this.$message({
                    message: '添加辅料成功!',
                    type: 'success'
                });
            },
            addAccessoryItem(row){
                console.log(row)
                //查找是否已经存在该项目
                let index = this.loss.accessoriesList.findIndex(item => item.name == row.name
                    && item.spec == row.spec)
                if(index === -1){
                    this.loss.accessoriesList.push({
                        name: row.name,
                        spec: row.spec,
                        qty: 1,
                        price: row.price,
                        amount: this.common.accMul(1, row.price)
                    })
                }else {
                    this.loss.accessoriesList[index].qty++
                    this.loss.accessoriesList[index].amount = this.common.accMul(this.loss.accessoriesList[index].qty, row.price)
                }
                this.calcAmount()
            },
            handleAddCustome(row){
                console.log(row)
                if(row.type == '换件') {
                    let item = {
                        productId: row.productId,
                        productCode: row.productCode,
                        productName: row.productName,
                        qty: row.qty,
                        price: (this.common.accDiv(row.amount, row.qty)).toFixed(2),
                        amount: row.amount,
                        remark: row.remark,
                        maxLoadQty: 9999,
                    }
                    this.loss.changeList.push(item)
                }else {
                    let labor = {
                        productId: row.productId,
                        productCode: row.productCode,
                        productName: row.productName,
                        type: row.type,
                        qty: row.qty,
                        price: (this.common.accDiv(row.amount, row.qty)).toFixed(2),
                        amount: row.amount
                    }
                    this.loss.laborList.push(labor)
                    this.calcAmount()
                }
                this.$message({
                    message: '添加自定义项目成功!',
                    type: 'success'
                });
            },
		}
	}
</script>

<style>
</style>
