<template>
    <div class="container">
        <div class="query-search">
            <div v-show="false" class="headline">车辆信息</div>
            <div v-show="false" class="title">VIN码：</div>
            <el-input v-show="false" placeholder="请输入VIN码后8位" v-model="searchInput" class="input-with-select">
                <el-button @click="handleSearch" slot="append" icon="el-icon-search" class="main-btn">
                </el-button>
            </el-input>
            <div class="title">系统品牌：</div>
            <el-select v-model="searchSelect" placeholder="请选择系统品牌" @change="searchSelectChange">
                <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-button class="main-btn stereotype-btn" @click="stereoType">车辆定型</el-button>
        </div>
        <pinfo :loss="loss"></pinfo>
        <div class="loss-order">
            <div class="headline">定损单</div>
            <el-tabs class="main-tabs" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="1"></el-tab-pane>
                <el-tab-pane :label="'换件信息('+loss.changeList.length+')'" name="2"></el-tab-pane>
                <el-tab-pane :label="'工时信息（'+loss.laborList.length+'）'" name="3"></el-tab-pane>
                <el-tab-pane :label="'辅料信息（'+loss.accessoriesList.length+'）'" name="4"></el-tab-pane>
            </el-tabs>
            <div class="loss-title" style="margin-top: 0;" v-if="replaceShow">
                <span class="title">换件信息</span>
                <el-button class="main-btn" icon="el-icon-plus" @click="addClick">添加配件</el-button>
            </div>
            <replaceTable @selectionChange="replaceSelectChange" v-if="replaceShow"
                          :tableData="loss.changeList"
                          @onUpdateAmount="handleUpdateChangeAmount"
            >
            </replaceTable>
            <div class="loss-title" v-if="hourShow">
                <span class="title">工时信息</span>
                <el-button class="main-btn" icon="el-icon-plus" @click="addClick">添加工时</el-button>
            </div>
            <hoursTable @selectionChange="hourSelectChange" v-if="hourShow"
                        @updateAmount="handleUpdateLaborAmount"
                        :tableData="loss.laborList"></hoursTable>
            <div class="loss-title" v-if="materialShow">
                <span class="title">辅料信息</span>
                <el-button class="main-btn" icon="el-icon-plus" @click="addClick">添加辅料</el-button>
            </div>
            <materialTable @selectionChange="materialSelectChange" v-if="materialShow"
                           @onUpdateAmount="handleUpdateAccessoryAmount"
                           :tableData="loss.accessoriesList"></materialTable>
        </div>
        <div class="insure-bottom">
            <div class="left">
                <ul>
                    <li><span>换件合计</span><em>￥{{ loss.changeAmount }}</em></li>
                    <li><span>工时合计</span><em>￥{{ loss.laborAmount }}</em></li>
                    <li><span>辅料合计</span><em>￥{{ loss.accessoriesAmount }}</em></li>
                    <li><span>总计</span><em>￥{{ loss.amount }}</em></li>
                </ul>
            </div>
            <div>
                <el-button class="main-btn" icon="el-icon-upload2" @click="submitClick">保存</el-button>
                <el-button class="main-btn" icon="" @click="handleDownload">导出</el-button>
            </div>
        </div>
        <el-dialog title="车辆定型" :visible.sync="dialogVisible" custom-class="main-dialog" width="1000px">
            <stereotype @onPinfoSelected="handlePinfoSelected"></stereotype>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="defineClick">确 定</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
import replaceTable from "./components/replaceTable.vue"
import hoursTable from "./components/hoursTable.vue"
import materialTable from "./components/materialTable.vue"
import stereotype from "./components/stereotype.vue"
import pinfo from "./components/pinfo.vue"
import {
    fetchLoss,
    saveLoss
} from "@/api/loss.js"
import {fetchVin} from "@/api/pinfo";

const emptyLoss = {
    id: '',
    pinfoId: '',
    pinfo: {},
    vininfo: {},
    changeList: [],
    laborList: [],
    accessoriesList: [],
    amount: 0,
    changeAmount: 0,
    laborAmount: 0,
    accessoriesAmount: 0,
}

export default {
    components: {
        replaceTable,
        hoursTable,
        materialTable,
        stereotype,
        pinfo
    },
    data() {
        return {
            loss: Object.assign({}, emptyLoss),
            newLoss: {},
            replaceShow: true,
            hourShow: true,
            materialShow: true,
            dialogVisible: false,
            activeName: '1',
            searchInput: '',
            searchSelect: "1",
            searchOptions: [{
                value: '1',
                label: '一汽解放'
            }, {
                value: '2',
                label: '重汽'
            }, {
                value: '3',
                label: '东风'
            }],
        }
    },
    created() {
        if (this.$route.params.loss) {
            this.loss = this.$route.params.loss;
            if (this.loss.vin) {
                this.searchInput = this.loss.vin
            }
        }
    },
    methods: {
        handleUpdateChangeAmount(row, amount) {
            this.loss.changeList.find(item => item.productId === row.productId
                && item.productName == row.productName).amount = amount;
            this.common.calcAmount(this.loss)
        },
        handleUpdateLaborAmount(row, amount) {
            this.loss.laborList.find(item => item.productId === row.productId
                && item.productName == row.productName
                && item.type == row.type
            ).amount = amount;
            this.common.calcAmount(this.loss)
        },
        handleUpdateAccessoryAmount(row, amount) {
            this.loss.accessoriesList.find(item => item.name === row.name
                && item.spec == row.spec
            ).amount = amount;
            this.common.calcAmount(this.loss)
        },
        defineClick() {
            this.loss = Object.assign({}, emptyLoss)
            this.loss.pinfo = this.newLoss.pinfo
            this.loss.pinfoId = this.loss.pinfo.id
            this.loss.vin = this.newLoss.vin
            this.loss.vinFull = this.newLoss.vinFull
            this.loss.vininfoId = this.newLoss.vininfoId
            this.loss.vininfo = this.newLoss.vininfo
            this.dialogVisible = false
            this.loss.changeList = []
            this.loss.laborList = []
            this.loss.accessoriesList = []
            this.$router.push({
                name: 'insureDetails',
                params: {
                    loss: this.loss
                }
            })
        },
        stereoType() {
            this.dialogVisible = true
        },
        submitClick() {
            this.$confirm('确定要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                saveLoss(this.loss).then(() => {
                    this.$message({
                        type: 'success',
                        message: '提交成功!'
                    });
                    this.loss = Object.assign({}, emptyLoss)
                })
            })
        },
        replaceSelectChange(selectData) {
            this.replaceTotal = 0
            selectData.forEach((item) => {
                this.replaceTotal += Number(item.price)
            })
        },
        hourSelectChange(selectData) {
            this.hoursTotal = 0
            selectData.forEach((item) => {
                this.hoursTotal += Number(item.price)
            })
        },
        materialSelectChange(selectData) {
            this.materialTotal = 0
            selectData.forEach((item) => {
                this.materialTotal += Number(item.price)
            })
        },
        addClick() {
            if (!this.loss.pinfoId) {
                this.$message({
                    type: 'error',
                    message: '请先进行车辆定型!'
                });
                return;
            }
            this.$router.push({
                name: 'insureDetails',
                params: {
                    loss: this.loss
                }
            })
        },
        handleClick() {
            console.log(this.activeName)
            if (this.activeName == "1") {
                this.replaceShow = true
                this.hourShow = true
                this.materialShow = true
            }
            if (this.activeName == "2") {
                this.replaceShow = true
                this.hourShow = false
                this.materialShow = false
            }
            if (this.activeName == "3") {
                this.replaceShow = false
                this.hourShow = true
                this.materialShow = false
            }
            if (this.activeName == "4") {
                this.replaceShow = false
                this.hourShow = false
                this.materialShow = true
            }
        },
        handleSearch() {
            fetchLoss({
                vin: this.searchInput
            }).then(res => {
                this.loss = res.data
                this.getVininfo()
            })
        },
        getVininfo() {
            fetchVin({condition: this.searchInput}).then(res => {
                let vinInfo = res.data;
                this.loss.vininfo = vinInfo
            })
        },
        searchSelectChange(val) {
            console.log(val)
        },
        handlePinfoSelected(newLoss) {
            this.newLoss = newLoss
        },
        handleDownload() {
            this.downloadLoading = true
            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = ['', '', '', '', '', '', '', '']
                const filterVal = ['val1', 'val2', 'val3', 'val4', 'val5', 'val6', 'val7', 'val8']
                let list = []
                //add vin info to list
                list.push({
                    val1: '承保VIN:',
                    val2: this.loss.vin,
                    val3: '承保品牌:',
                    val4: '一汽解放:',
                    val5: '发动机型号:',
                    val6: this.loss.pinfo.engineTypeText,
                    val7: '定损VIN:',
                    val8: this.loss.vin
                })
                list.push({
                    val1: '承保车型:',
                    val2: this.loss.pinfo.productText,
                    val3: '变速箱型号:',
                    val4: this.loss.pinfo.gearboxAndModelText,
                    val5: '动力类型:',
                    val6: this.loss.pinfo.oilTypeText,
                    val7: '车辆颜色:',
                    val8: this.loss.pinfo.colorText
                })
                //add empty item to list
                list.push({
                    val1: '',
                    val2: '',
                    val3: '',
                    val4: '',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })

                //add change list to list
                list.push({
                    val1: '换件信息',
                    val2: '',
                    val3: '',
                    val4: '',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                list.push({
                    val1: '零件名称',
                    val2: '零件编码',
                    val3: '数量',
                    val4: '定损价(元)',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                this.loss.changeList.forEach((item) => {
                    list.push({
                        val1: item.productName,
                        val2: item.productCode,
                        val3: item.qty,
                        val4: item.amount,
                        val5: '',
                        val6: '',
                        val7: '',
                        val8: ''
                    })
                })
                //add empty item to list
                list.push({
                    val1: '',
                    val2: '',
                    val3: '',
                    val4: '',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                //add hour list to list
                list.push({
                    val1: '工时信息',
                    val2: '',
                    val3: '',
                    val4: '',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                list.push({
                    val1: '零件名称',
                    val2: '工时(h)',
                    val3: '工种',
                    val4: '备注',
                    val5: '工时费(元)',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                this.loss.laborList.forEach((item) => {
                    list.push({
                        val1: item.productName,
                        val2: item.qty,
                        val3: item.type,
                        val4: item.remark,
                        val5: item.amount,
                        val6: '',
                        val7: '',
                        val8: ''
                    })
                })
                //add empty item to list
                list.push({
                    val1: '',
                    val2: '',
                    val3: '',
                    val4: '',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                //add material list to list
                list.push({
                    val1: '辅料信息',
                    val2: '',
                    val3: '',
                    val4: '',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                list.push({
                    val1: '辅料名称',
                    val2: '规格',
                    val3: '数量',
                    val4: '定损价(元)',
                    val5: '',
                    val6: '',
                    val7: '',
                    val8: ''
                })
                this.loss.accessoriesList.forEach((item) => {
                    list.push({
                        val1: item.name,
                        val2: item.spec,
                        val3: item.qty,
                        val4: item.amount,
                        val5: '',
                        val6: '',
                        val7: '',
                        val8: ''
                    })
                })
                const data = this.formatJson(filterVal, list)
                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: "定损单-"+this.loss.vin,
                    autoWidth: false,
                    bookType: 'xlsx'
                })
                this.downloadLoading = false
            })
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                if (j === 'state' && v[j]) {
                    return this.stateFormat({
                        state: v[j]
                    })
                } else {
                    return v[j]
                }
            }))
        },
    }
}
</script>

<style lang="scss">
</style>
