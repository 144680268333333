<template>
	<div class="insure-details-con">
		<div class="query-search">
			<div class="left">
				<el-input placeholder="请输入配件名称或原厂零件号" v-model="query.keyword"
                          clearable
                          class="input-with-select">
					<el-button @click="handleSearch" slot="append" icon="el-icon-search" class="main-btn">
					</el-button>
				</el-input>
				<el-button class="main-btn" @click="customBtn">自定义添加</el-button>
				<el-button class="main-btn" @click="changeBtn">换件</el-button>
				<el-button class="main-btn" @click="dismantleBtn">拆装</el-button>
				<el-button class="main-btn" @click="materialBtn">辅料</el-button>
				<el-tooltip class="item" effect="dark" content="换件/拆装/辅料按钮可以批量添加对应的损失项目" placement="top-start">
					<i class="el-icon-warning"></i>
				</el-tooltip>
			</div>
			<el-button class="define-btn" type="success" @click="submit">确定</el-button>
		</div>
        <div class="info">
            <div class="info-item">
                <div class="title">VIN</div>
                <div class="content">
                    <el-tooltip :content="vininfo.vin" placement="top-start">
                        <div class="line2">{{vininfo.vin}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">品牌</div>
                <div class="content">
                    <el-tooltip content="解放" placement="top-start">
                        <div class="line2">解放</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">品牌说明</div>
                <div class="content">
                    <el-tooltip :content="pinfo.brandText" placement="top-start">
                        <div class="line2">{{pinfo.brandText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">品系</div>
                <div class="content">
                    <el-tooltip :content="pinfo.pdtSeriesText" placement="top-start">
                        <div class="line2">{{pinfo.pdtSeriesText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">品名</div>
                <div class="content">
                    <el-tooltip :content="pinfo.productText" placement="top-start">
                        <div class="line2">{{pinfo.productText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">车辆下线日期</div>
                <div class="content">
                    <el-tooltip :content="this.common.formatDate(vininfo.offlineDate, 'yyyy-MM-dd')" placement="top-start">
                        <div class="line2">{{this.common.formatDate(vininfo.offlineDate, 'yyyy-MM-dd')}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">排放</div>
                <div class="content">
                    <el-tooltip :content="pinfo.emissionTypeText" placement="top-start">
                        <div class="line2">{{pinfo.emissionTypeText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">颜色</div>
                <div class="content">
                    <el-tooltip :content="pinfo.colorText" placement="top-start">
                        <div class="line2">{{pinfo.colorText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">发动机</div>
                <div class="content">
                    <el-tooltip :content="pinfo.engineTypeText" placement="top-start">
                        <div class="line2">{{pinfo.engineTypeText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">驾驶室</div>
                <div class="content">
                    <el-tooltip :content="pinfo.cabTypeConfigText" placement="top-start">
                        <div class="line2">{{pinfo.cabTypeConfigText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">变速箱</div>
                <div class="content">
                    <el-tooltip :content="pinfo.gearboxAndModelText" placement="top-start">
                        <div class="line2">{{pinfo.gearboxAndModelText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">后桥</div>
                <div class="content">
                    <el-tooltip :content="pinfo.realAxleAssemblyText" placement="top-start">
                        <div class="line2">{{pinfo.realAxleAssemblyText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">后桥速比</div>
                <div class="content">
                    <el-tooltip :content="pinfo.realAxleSpeedText" placement="top-start">
                        <div class="line2">{{pinfo.realAxleSpeedText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">轮胎</div>
                <div class="content">
                    <el-tooltip :content="pinfo.tyreTypeText" placement="top-start">
                        <div class="line2">{{pinfo.tyreTypeText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">轴距</div>
                <div class="content">
                    <el-tooltip :content="pinfo.freightBoxText" placement="top-start">
                        <div class="line2">{{pinfo.freightBoxText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item">
                <div class="title">前桥</div>
                <div class="content">
                    <el-tooltip :content="pinfo.farwardText" placement="top-start">
                        <div class="line2">{{pinfo.farwardText}}</div>
                    </el-tooltip>
                </div>
            </div>
            <div class="info-item w100">
                <div class="title">其他</div>
                <div class="content">
                    <el-tooltip :content="pinfo.others" placement="top-start">
                        <div class="line2">{{pinfo.others}}</div>
                    </el-tooltip>
                </div>
            </div>
        </div>
		<sparesTable @selectionChange="spareSelectChange"
                     :tableData="tableData"
                     :pinfo="pinfo"
                     :total="total"
                     :page="query.page"
                     :pagesize="query.pagesize"
                     @onPageChange="handlePageChange"
                     @operateClick="operateClick"
                     :loading="loading"
			@workClick="workClick"></sparesTable>
		<div class="tabs-header">
			<el-tabs class="main-tabs" v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="换件" name="1"></el-tab-pane>
				<el-tab-pane label="工时" name="2"></el-tab-pane>
				<el-tab-pane label="辅料" name="3"></el-tab-pane>
			</el-tabs>
			<el-button class="del-btn" type="danger" @click="delBtn">一键删除</el-button>
		</div>

		<replaceTable @selectionChange="replaceSelectChange" v-show="replaceShow" :priceShow="false"
                      @onUpdateQty="handleUpdateChangeQty"
			:tableData="loss.changeList"></replaceTable>
		<hoursTable @selectionChange="hourSelectChange" v-show="hourShow" :priceShow="false"
                    @onUpdateQty="handleUpdateHourQty"
                    :tableData="loss.laborList">
		</hoursTable>
		<materialTable @selectionChange="materialSelectChange" v-show="materialShow" :priceShow="false"
                       @onUpdateQty="handleUpdateAccessoryQty"
			:tableData="loss.accessoriesList"></materialTable>
		<!-- 自定义添加 -->
		<el-dialog title="自定义项目" :visible.sync="dialogFormVisible" custom-class="main-dialog">
			<el-form ref="customForm" :model="ruleForm" :rules="rules" label-width="120px">
				<el-form-item label="项目类型" prop="type">
					<el-radio-group v-model="ruleForm.type">
						<el-radio-button label="换件" name="type"></el-radio-button>
						<el-radio-button label="拆装" name="type"></el-radio-button>
						<el-radio-button label="喷漆" name="type"></el-radio-button>
						<el-radio-button label="钣金" name="type"></el-radio-button>
						<el-radio-button label="机修" name="type"></el-radio-button>
						<el-radio-button label="电工" name="type"></el-radio-button>
						<el-radio-button label="外修" name="type"></el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="数量" prop="qty">
					<el-input-number v-model="ruleForm.qty" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="项目名称" prop="productName">
					<el-input v-model="ruleForm.productName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="损失金额" prop="amount">
					<el-input v-model="ruleForm.amount" autocomplete="off"
                              oninput = "value=value.replace(/[^\d.]/g,'')"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" v-model="ruleForm.remark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="onAddCustome">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 辅料 -->
		<el-dialog title="辅料" :visible.sync="dialogTableVisible" custom-class="main-dialog material-add">
			<el-button class="main-btn" @click="materialAdd">添加辅料</el-button>
			<el-table :data="gridData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName">
				<el-table-column property="name" label="辅料名称">
					<template slot-scope="scope">
						<div v-if="scope.row.materialReadonly">{{scope.row.name}}</div>
						<el-input v-model="scope.row.name" placeholder="请输入辅料名称" v-if="!scope.row.materialReadonly"></el-input>
					</template>
				</el-table-column>
				<el-table-column property="specs" label="规格">
					<template slot-scope="scope">
						<div v-if="scope.row.materialReadonly">{{scope.row.spec}}</div>
						<el-input v-model="scope.row.spec" placeholder="请输入规格"  v-if="!scope.row.materialReadonly"></el-input>
					</template>
				</el-table-column>
                <el-table-column property="price" label="价格">
                    <template slot-scope="scope">
                        <div v-if="scope.row.materialReadonly">{{scope.row.price}}</div>
                        <el-input v-model="scope.row.price"
                                  oninput = "value=value.replace(/[^\d.]/g,'')"
                                  placeholder="请输入价格"  v-if="!scope.row.materialReadonly"></el-input>
                    </template>
                </el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="addClick(scope.row)" size="mini" type="primary">+ 添加</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import sparesTable from "./sparesTable.vue"
	import replaceTable from "./replaceTable.vue"
	import hoursTable from "./hoursTable.vue"
	import materialTable from "./materialTable.vue"
	import {
		fetchList
	} from "../../../api/product";
    import upList from "@/api/upList";

	export default {
		components: {
			sparesTable,
			replaceTable,
			hoursTable,
			materialTable
		},
		props: ['search', 'lossObj'],
		data() {
			return {
                loading: false,
				loss: {},
                pinfo: {},
                vininfo: {},
				tableData: [],
				total: '',
				query: {
					keyword: '',
					categoryId: '',
					pinfoId: '',
					page: 1,
					pagesize: 12,
					// sortProp: 'price_end_user',
					// sortOrder: 'desc',

                    //unuse product common data
                    sort: '-price_end_user,-price_xichai',
                    data: 'labor,relative,insurePrice'

                    //use product common data
                    // sort: '-price',
                    // data: 'labor,insurePrice',
                    // isCommon: 1
				},
				selectSparesData: [],
				gridData: [{
					spec: '1L',
					name: '防冻液',
					materialReadonly: true,
                    price: 100
				}, {
					spec: '2L',
					name: '润滑油',
					materialReadonly: true,
                    price: 100
				}],
				dialogTableVisible: false,
				dialogFormVisible: false,
				ruleForm: {
                    productId: 0,
                    productCode: '',
					type: [],
					productName: '',
					qty: 1,
					price: '',
					remark: ''
				},
                rules: {
                    type: [
                        {
                            required: true,
                            message: '请选择项目类型',
                            trigger: 'change'
                        }
                    ],
                    qty: [
                        {
                            required: true,
                            message: '请输入数量',
                            trigger: 'change'
                        }
                    ],
                    productName: [
                        {
                            required: true,
                            message: '请输入项目名称',
                            trigger: 'blur'
                        }
                    ],
                    amount: [
                        {
                            required: true,
                            message: '请输入损失金额',
                            trigger: 'blur'
                        }
                    ]
                },
				selectReplaceData: [],
				selectHourData: [],
				selectMaterialData: [],
				activeName: "1",
				replaceShow: true,
				hourShow: false,
				materialShow: false
			}
		},
		watch: {
			activeName() {
				this.handleClick()
			},
			search(val) {
				this.query.categoryId = val.categoryId
				this.query.pinfoId = val.pinfoId
                this.query.page = 1
				this.getList()
			},
			lossObj: {
				handler(val) {
					this.loss = val
                    console.log(this.loss)
                    this.pinfo = this.loss.pinfo
                    this.vininfo = this.loss.vininfo
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
            handlePageChange(val){
                this.query.page = val
                this.getList()
            },
            handleUpdateChangeQty(row, qty){
                this.$emit('onUpdateChangeQty', row, qty)
            },
            handleUpdateHourQty(row, qty){
                this.$emit('onUpdateHourQty', row, qty)
            },
            handleUpdateAccessoryQty(row, qty){
                this.$emit('onUpdateAccessoryQty', row, qty)
            },
			materialAdd(){
				this.gridData.unshift({
					spec: '',
					name: '',
					materialReadonly: false,
                    price: 0
				})
			},
			delBtn() {
				let length = this.selectReplaceData.length + this.selectHourData.length + this.selectMaterialData.length
				if (length === 0) {
					this.$message({
						message: '至少选择一个项目',
						type: 'warning'
					});
				} else {
					this.$confirm('确定删除选中的所有选项吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$emit('onRemove', this.selectReplaceData, this.selectHourData, this
							.selectMaterialData)
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}

			},
			workClick(row, val, qty, remark) {
				this.activeName = "2"
				this.$emit('onAddLabor', row, val, qty, remark)
			},
			operateClick(row) {
				this.activeName = "1"
				this.$emit('onAddChange', row)
			},
			spareSelectChange(selectData) {
				this.selectSparesData = selectData
			},
			replaceSelectChange(selectData) {
				this.selectReplaceData = selectData
                console.log(this.selectReplaceData)
			},
			hourSelectChange(selectData) {
				this.selectHourData = selectData
                console.log(this.selectHourData)
			},
			materialSelectChange(selectData) {
				this.selectMaterialData = selectData
			},
			handleClick() {
				if (this.activeName == "1") {
					this.replaceShow = true
					this.hourShow = false
					this.materialShow = false
				}
				if (this.activeName == "2") {
					this.replaceShow = false
					this.hourShow = true
					this.materialShow = false
				}
				if (this.activeName == "3") {
					this.replaceShow = false
					this.hourShow = false
					this.materialShow = true
				}
			},
			addClick(row) {
                if(!row.name){
                    this.$message.error('请输入辅料名称')
                    return
                }
                this.$emit('onAddAccessory', row)
			},
			materialBtn() {
				this.dialogTableVisible = true
				// if (this.selectSparesData.length === 0) {
				// 	this.$message({
				// 		message: '至少选择一个零件',
				// 		type: 'warning'
				// 	});
				// } else {
					this.activeName = "3"
					this.dialogTableVisible = true
				// }
			},
			dismantleBtn() {
				if (this.selectSparesData.length === 0) {
					this.$message({
						message: '至少选择一个零件',
						type: 'warning'
					});
				} else {
					this.activeName = "2"
					this.$emit('onAddLaborList', this.selectSparesData, '拆装')
				}
			},
			changeBtn() {
				if (this.selectSparesData.length === 0) {
					this.$message({
						message: '至少选择一个零件',
						type: 'warning'
					});
				} else {
					this.activeName = "1"
					this.$emit('onAddChangeList', this.selectSparesData)
				}
			},
			customBtn() {
                this.ruleForm = {
                    productId: 0,
                        productCode: '',
                        type: [],
                        productName: '',
                        qty: 1,
                        price: '',
                        remark: ''
                }
				this.dialogFormVisible = true
			},
			handleSearch() {
                this.query.page = 1
                this.getList()
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                this.loading = true
                if(this.query.categoryId >= 10000){
                    upList.getList(upList.data, this.query).then(res => {
                        this.tableData = res.list
                        this.total = res.total
                    }).finally(() => {
                        this.loading = false
                    })
                }else {
                    fetchList(this.query).then(res => {
                        this.tableData = res.data.list
                        this.total = res.data.total
                    }).finally(() => {
                        this.loading = false
                    })
                }
			},
			submit() {
				this.$emit('onSubmit')
			},
            onAddCustome(){
                //vue form validation
                this.$refs['customForm'].validate((valid) => {
                    if (valid) {
                        this.$emit('onAddCustome',this.ruleForm)
                        this.dialogFormVisible = false
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
		}
	}
</script>

<style lang="scss">
</style>
