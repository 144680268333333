<template>
    <div class="info">
        <div class="info-item">
            <div class="title">承保VIN</div>
            <div class="content">
                <div class="line2" :title="loss.vin">{{loss.vin}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">承保品牌</div>
            <div class="content">
                <div class="line2" title="一汽解放">一汽解放</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">发动机型号</div>
            <div class="content">
                <div class="line2" :title="pinfo.engineTypeText">{{pinfo.engineTypeText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">定损VIN</div>
            <div class="content">
                <div class="line2" :title="loss.vin">{{loss.vin}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">承保车型</div>
            <div class="content">
                <div class="line2" :title="pinfo.productText">{{pinfo.productText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">变速箱型号</div>
            <div class="content">
                <div class="line2" :title="pinfo.gearboxAndModelText">{{pinfo.gearboxAndModelText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">动力类型</div>
            <div class="content">
                <div class="line2" :title="pinfo.oilTypeText">{{pinfo.oilTypeText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">车辆颜色</div>
            <div class="content">
                <div class="line2" :title="pinfo.colorText">{{pinfo.colorText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">车辆详情</div>
            <div class="content">
                <div class="line2" :title="pinfo.others">{{pinfo.others}}</div>
            </div>
        </div>
        <div class="info-item w75">
            <div class="title">排放标准</div>
            <div class="content">
                <div class="line2" :title="pinfo.emissionTypeText">{{pinfo.emissionTypeText}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pinfo",
    props: ['loss'],
    data() {
        return {
            pinfo: {
                productText: '',
                engineTypeText: '',
                gearboxAndModelText: '',
                oilTypeText: '',
                colorText: '',
                others: '',
                emissionTypeText: ''
            }
        }
    },
    watch: {
        loss: {
            handler(val) {
                this.pinfo = val.pinfo;
            },
            immediate: true,
            deep: true
        }
    },
}
</script>

<style scoped>

</style>
