<template>
	<div>
		<el-table :data="list" class="main-table" header-row-class-name="table-header" cell-class-name="table-hover"
			:row-class-name="tableRowClassName" @selection-change="selectionChange" max-height="500px"
			v-loading="loading" row-key="id" default-expand-all :tree-props="{children: 'children'}">
			<el-table-column width="80">
				<template slot-scope="scope">
					{{scope.row.id.toString().indexOf('-') > 0 ? '相似' : ''}}
				</template>
			</el-table-column>
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column type="index" label="序号" width="50">
			</el-table-column>
			<el-table-column prop="name" label="零件名称">
			</el-table-column>
			<el-table-column prop="buyCode" label="原厂零件号">
			</el-table-column>
			<el-table-column prop="laborItem.maxLoadQty" label="最大装车用量" align="center">
				<template slot-scope="{row}">
					{{(row.laborItem && row.laborItem.maxLoadQty) ? row.laborItem.maxLoadQty : '-'}}
				</template>
			</el-table-column>
<!--			<el-table-column prop="priceEndUser" label="指导价" align="center">-->
<!--				<template scope="{row}">-->
<!--					<span>{{ calcPrice(row) }}</span>-->
<!--				</template>-->
<!--			</el-table-column>-->
			<el-table-column prop="price2" label="服务站价" align="center">
				<template scope="{row}">
					{{ calcPriceService(row) }}
				</template>
			</el-table-column>
			<el-table-column prop="price3" label="精品件价格" align="center">
				<template scope="{row}">
					{{ calcBrandPrice(row) }}
				</template>
			</el-table-column>
			<el-table-column prop="price3" label="副厂件价格" align="center">
				<template scope="{row}">
					{{ calcSubFactoryPrice(row) }}
				</template>
			</el-table-column>
			<el-table-column prop="price3" label="原厂供货价" align="center">
                <template scope="{row}">
                    {{ calcSupplierPrice(row) }}
                </template>
            </el-table-column>
			<el-table-column label="照片" align="center" width="100" class-name="photo">
				<template slot-scope="{row}">
					<el-image class="photo-img" :src="row.imgs[0].img" v-if="row.imgs && row.imgs.length>0"
						:preview-src-list="row.imgs.map(e=>e.img)" @click="bigImg(row.imgs[0].img)"></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="" label="备注" align="center">
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" size="mini" @click="operateClick(scope.row)">换件</el-button>
					<el-button type="primary" size="mini" v-if="scope.row.laborItem && scope.row.laborItem.laborTime"
						@click="workClick(scope.row,'拆装', scope.row.laborItem.laborTime, scope.row.laborItem.laborTimeRemark)">
						拆装</el-button>
					<el-button type="primary" size="mini"
						v-if="scope.row.laborItem && scope.row.laborItem.electricLaborTime"
						@click="workClick(scope.row,'电工', scope.row.laborItem.electricLaborTime, scope.row.laborItem.electricLaborTimeRemark)">
						电工</el-button>
					<el-button type="primary" size="mini"
						v-if="scope.row.laborItem && scope.row.laborItem.outsourceLaborTime"
						@click="workClick(scope.row,'外修', scope.row.laborItem.outsourceLaborTime, scope.row.laborItem.outsourceLaborTimeRemark)">
						外修</el-button>
					<el-button type="primary" size="mini"
						v-if="scope.row.laborItem && scope.row.laborItem.machineLaborTime"
						@click="workClick(scope.row,'机修', scope.row.laborItem.machineLaborTime, scope.row.laborItem.machineLaborTimeRemark)">
						机修</el-button>
					<el-dropdown @command="dropdownCommand" size="mini"
						v-if="scope.row.laborItem && scope.row.laborItem.halfPaintLaborTime && scope.row.laborItem.fullPaintLaborTime">
						<el-button size="mini" type="primary">
							喷漆<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="scope.row.laborItem && scope.row.laborItem.halfPaintLaborTime"
								:command="beforeHandleCommand(scope.row,'半喷', scope.row.laborItem.halfPaintLaborTime, scope.row.laborItem.halfPaintLaborTimeRemark)">
								半喷</el-dropdown-item>
							<el-dropdown-item v-if="scope.row.laborItem && scope.row.laborItem.fullPaintLaborTime"
								:command="beforeHandleCommand(scope.row,'全喷', scope.row.laborItem.fullPaintLaborTime, scope.row.laborItem.fullPaintLaborTimeRemark)">
								全喷</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-dropdown
						v-if="scope.row.laborItem && scope.row.laborItem.lightMetalLaborTime && scope.row.laborItem.mediumMetalLaborTime && scope.row.laborItem.heavyMetalLaborTime"
						@command="dropdownCommand" size="mini">
						<el-button size="mini" type="primary">
							钣金<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="scope.row.laborItem && scope.row.laborItem.lightMetalLaborTime"
								:command="beforeHandleCommand(scope.row,'轻钣金', scope.row.laborItem.lightMetalLaborTime, scope.row.laborItem.lightMetalLaborTimeRemark)">
								轻钣金</el-dropdown-item>
							<el-dropdown-item v-if="scope.row.laborItem && scope.row.laborItem.mediumMetalLaborTime"
								:command="beforeHandleCommand(scope.row,'中钣金', scope.row.laborItem.mediumMetalLaborTime, scope.row.laborItem.mediumMetalLaborTimeRemark)">
								中钣金</el-dropdown-item>
							<el-dropdown-item v-if="scope.row.laborItem && scope.row.laborItem.heavyMetalLaborTime"
								:command="beforeHandleCommand(scope.row,'重钣金', scope.row.laborItem.heavyMetalLaborTime, scope.row.laborItem.heavyMetalLaborTimeRemark)">
								重钣金</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="page"
			:page-size="pagesize" @current-change="onPageChange">
		</el-pagination>
	</div>
</template>

<script>
import {
    calcBrandPrice,
    calcSubFactoryPrice,
    calcSupplierPrice,
    getPrice,
    getServicePrice
} from "../../../common/calcPrice";

    export default {
		props: ['tableData', 'total', 'page', 'pagesize', 'loading', 'pinfo'],
		data() {
			return {
				list: [],
				tableData1: [],
			}
		},
		watch: {
			tableData: {
				handler: function(val) {
					this.list = JSON.parse(JSON.stringify(val));
					this.list.forEach(e => {
						if (e.relatives && e.relatives.length > 0) {
							e.children = e.relatives
							e.children.forEach(c => {
								c.id = c.id + '-' + c.id
								c.priceEndUser = c.price
                                c.priceServiceTax = c.priceTax
                                let price = c.price
                                if(!price){
                                    price = c.priceNoTax
                                }
								c.priceBrand = (price * 1.13 * 1.05 * 0.75).toFixed(2)
								c.priceSubFactory = (price * 1.13 * 1.05 * 0.75 * 0.65).toFixed(
									2)
							})
						}
					})
				},
				deep: true
			}
		},
		methods: {
			calcPrice(row) {
				return getPrice(row)
			},
			calcPriceService(row) {
				return getServicePrice(row)
			},
            calcSupplierPrice(row){
                return calcSupplierPrice(row, this.pinfo)
            },
            calcBrandPrice(row){
                return calcBrandPrice(row, this.pinfo)
            },
            calcSubFactoryPrice(row){
                return calcSubFactoryPrice(row, this.pinfo)
            },
			onPageChange(val) {
				this.$emit('onPageChange', val)
			},
			workClick(row, val, qty, remark) {
				this.$emit("workClick", row, val, qty, remark)
			},
			operateClick(row) {
				this.$emit("operateClick", row)
			},
			beforeHandleCommand(row, command, qty, remark) {
				return {
					'row': row,
					'command': command,
					'qty': qty,
					'remark': remark
				}
			},
			dropdownCommand(command) {
				this.$emit("workClick", command.row, command.command, command.qty)
			},
			selectionChange(selectData) {
				this.$emit("selectionChange", selectData)
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
		}
	}
</script>
<style lang="scss">
	.el-button--mini {
		padding: 3px 6px;
		font-size: 12px;
	}

	.el-dropdown {
		margin-left: 10px;
	}

	.photo-img {
		width: .35rem;
		height: .35rem;
	}

	.el-table__indent {
		padding-left: 0px !important;
	}
</style>
