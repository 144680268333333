<template>
	<el-table :data="tableData" class="main-table" header-row-class-name="table-header" cell-class-name="table-hover"
		:row-class-name="tableRowClassName" @selection-change="selectionChange">
		<el-table-column type="selection" width="55">
		</el-table-column>
		<el-table-column type="index" label="序号" width="50">
		</el-table-column>
		<el-table-column prop="productName" label="零件名称">
		</el-table-column>
		<el-table-column prop="productCode" label="零件编码">
		</el-table-column>
		<el-table-column prop="qty" label="数量" align="center">
			<template slot-scope="{row}">
				<div v-if="priceShow">{{row.qty}}</div>
				<el-input-number v-model="row.qty" :min="1" :max="row.maxLoadQty ? row.maxLoadQty : 1" label="数量"
                                 v-if="!priceShow" @change="numberChange($event, row)"></el-input-number>
			</template>
		</el-table-column>
		<el-table-column prop="amount" label="定损价（元）" align="center">
			<template slot-scope="{row}">
				<div v-if="!priceShow">{{row.amount}}</div>
				<el-input v-model="row.amount" placeholder="请输入定损价"
                          @keyup.native="row.amount = oninput(row.amount)"
                          @change="onUpdateAmount(row, row.amount)" v-if="priceShow"/>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	export default {
		props: {
			tableData: Array,
			priceShow: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {}
		},
		methods: {
			numberChange(e, row){
                this.$emit('onUpdateQty', row, e)
			},
			oninput(num) {
				var str = num
				var len1 = str.substr(0, 1)
				var len2 = str.substr(1, 1)
				//如果第一位是0，第二位不是点，就用数字把点替换掉
				if (str.length > 1 && len1 == 0 && len2 != ".") {
					str = str.substr(1, 1)
				}
				//第一位不能是.
				if (len1 == ".") {
					str = ""
				}
				//限制只能输入一个小数点
				if (str.indexOf(".") != -1) {
					var str_ = str.substr(str.indexOf(".") + 1)
					if (str_.indexOf(".") != -1) {
						str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
					}
				}
				//正则替换
				str = str.replace(/[^\d^.]+/g, '') // 保留数字和小数点
				return str
			},
            onUpdateAmount(row, amount) {
                this.$emit('onUpdateAmount', row, amount)
            },
			selectionChange(selectData) {
				this.$emit("selectionChange", selectData)
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
		}
	}
</script>
