<template>
	<div class="stereotype clearfix">
		<div class="left" element-loading-text="正在查询数据...">
            <div v-loading="loading">
                <div class="stereotype-title"><span>VIN码定型</span></div>
                <el-input placeholder="请输入VIN码(至少后8位)" v-model="searchInput" @input="handleInputVin"
                          class="input-with-select">
                    <el-button @click="handleSearch" slot="append" icon="el-icon-search" class="main-btn">查询
                    </el-button>
                </el-input>
                <pinfoMini :pinfo="loss.pinfo" :vininfo="loss.vininfo"></pinfoMini>
            </div>
            <el-button style="float:right" v-if="loading" type="warning" @click="cancelSearch">取消查询</el-button>
		</div>
		<div class="right" v-loading="loading">
			<div class="stereotype-title"><span>已选定车型</span></div>
			<el-image class="img" :src="imgUrl" fit="contain"></el-image>
			<div class="info">
				<div class="info-item">
					<div class="title">车型</div>
					<div class="content">{{loss.pinfo.productText}}</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
    import pinfoMini from './pinfoMini.vue'
    import { fetchVin, fetchPinfo } from "@/api/pinfo";

    export default{
        components: {
            pinfoMini
        },
		data(){
			return{
                loading: false,
				searchInput:'',
                loss: {
                    vininfoId: '',
                    vin: '',
                    vininfo: {},
                    pinfoId: '',
                    pinfo: {}
                },
				imgUrl:require("@/assets/img/no-img.jpg"),
			}
		},
        methods:{
            cancelSearch(){
                console.log(this.$httpRequestList)
                this.$httpRequestList.forEach((item) => {
                    // 给个标志，中断请求
                    item('interrupt');
                })
                this.loading = false;
            },
            handleInputVin(e){
                this.searchInput = e.toUpperCase()
            },
            handleSearch(){
                //searchInput不足8位报错
                if(this.searchInput.length < 8){
                    this.$message.error('请输入至少后8位VIN号');
                    return;
                }
                this.loading = true;
                fetchVin({ condition: this.searchInput }).then(res => {
                    let vinInfo = res.data;
                    this.loss.vin = vinInfo.vin;
                    this.loss.vinFull = vinInfo.vinFull;
                    this.loss.vininfoId = vinInfo.id;
                    this.loss.vininfo = vinInfo
                    fetchPinfo({ id: vinInfo.pinfoId }).then(response => {
                        this.loading = false
                        this.loss.pinfo = response.data;
												this.imgUrl = require("@/assets/img/no-img2.jpg")
                        this.$emit('onPinfoSelected', this.loss);
                    });
                })
            }
        }
	}
</script>

<style lang="scss">

</style>
