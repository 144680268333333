<template>
    <div class="info">
        <div class="info-item">
            <div class="title">车型</div>
            <div class="content">
                <div :title="pinfo.productText">{{pinfo.productText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">品牌</div>
            <div class="content">
                <div title="一汽解放">一汽解放</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">品牌说明</div>
            <div class="content">
                <div :title="pinfo.brandText">{{pinfo.brandText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">车辆下线日期</div>
            <div class="content">
                <div :title="this.common.formatDate(vininfo.offlineDate, 'yyyy-MM-dd')">{{this.common.formatDate(vininfo.offlineDate, 'yyyy-MM-dd')}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">颜色</div>
            <div class="content">
                <div :title="pinfo.colorText">{{pinfo.colorText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">排放标准</div>
            <div class="content">
                <div :title="pinfo.emissionTypeText">{{pinfo.emissionTypeText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">动力类型</div>
            <div class="content">
                <div :title="pinfo.oilTypeText">{{pinfo.oilTypeText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">发动机型号</div>
            <div class="content">
                <div :title="pinfo.engineTypeText">{{pinfo.engineTypeText}}</div>
            </div>
        </div>
        <div class="info-item">
            <div class="title">车辆详情</div>
            <div class="content">
                <div :title="pinfo.others">{{pinfo.others}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pinfoMini",
    props: ['pinfo', 'vininfo']
}
</script>

<style scoped>

</style>
